const getInventorySlots = state => {
  return state.inventorySlots;
};

const getVendorCurrentPatientInvoice = state => {
  return state.vendorInvoice;
}

export default {
  getInventorySlots,
  getVendorCurrentPatientInvoice,
}
