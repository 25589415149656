import pharmacyIcon from "@/assets/placeholders/pharmacy.png";
import nutraceuticalsIcon from "@/assets/placeholders/nutraceuticals.png";
import medicalEquipmentIcon from "@/assets/placeholders/medical-equipment.png";
import pharmaceuticalsIcon from "@/assets/placeholders/pharmaceuticals.png";
import medicalSuppliesIcon from "@/assets/placeholders/medical-supplies.png";
import verifyIdMe from "@/assets/biote/IDme/verify.svg";
import idmeBioteLogo from "@/assets/biote/IDme/biote-logo.png";
import idmeLogo from "@/assets/biote/IDme/idme-logo.svg";
import idmePlus from "@/assets/biote/IDme/idme-plus.svg";

// eslint-disable-next-line import/prefer-default-export
export const findCategoryImage = primaryCategory => {
  switch (primaryCategory) {
    case "Pharmacy":
      return pharmacyIcon;
    case "Nutraceuticals":
      return nutraceuticalsIcon;
    case "Medical Equipment":
      return medicalEquipmentIcon;
    case "Pharmaceuticals":
      return pharmaceuticalsIcon;
    case "Medical Supplies":
      return medicalSuppliesIcon;
    default:
      return "https://via.placeholder.com/300x300/eeeeee/8f9398?text=No%20Image%20Provided";
  }
};

export const findButtonImage = buttonImg => {
  switch (buttonImg) {
    case "idme":
      return verifyIdMe;
    case "idme-biote-logo":
      return idmeBioteLogo;
    case "idme-logo":
      return idmeLogo;
    case "idme-plus":
      return idmePlus;
    default:
      return null;
  }
};
