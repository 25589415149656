const setProductFormDetails = (state, payload) => {
  state.productDetailsForm = payload;
}

const updateProductForm = (state, payload) => {
  state.productDetailsForm[payload.fieldName] = payload.value;
}

const setProductPricing = (state, payload) => {
  state.pricing = payload;
}

const setProductsBundle = (state, payload) => {
  state.productDetailsForm.productBundle = payload;
}

const resetProductFormDetails = state => {
  const details = {
    boundByState: false,
    description: "",
    detail: "",
    id: "",
    images: [],
    manufacturer: "",
    name: "",
    categorySlug: "",
    subcategorySlug: "",
    productPricing: {},
    registrationRequired: false,
    sku: "",
    vendorSku: "",
    mfgSku: "",
    slug: "",
    vendor: {},
    weight: 0,
    weightTypeId: 1,
    cardVolume: "",
    marketplaceStoreFront: true,
    practiceStoreFront: false,
    prescriptionStoreFront: false,
    rateType: "C",
    isVisibleMedcenter: false,
    minQtyForPurchase: 0,
    isVirtualProduct: false,
    limited: false
  };
  state.productDetailsForm = details
}

const setVendorPricingTiers = (state, payload) => {
  state.pricingTiers = payload;
}

export default {
  setProductFormDetails,
  updateProductForm,
  setProductPricing,
  resetProductFormDetails,
  setProductsBundle,
  setVendorPricingTiers
}
