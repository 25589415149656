const getVendorTierPrices = state => {
  return state.vendorTierPrices;
}

const getVendorClinics = state => {
  return state.vendorClinics;
}

export default {
  getVendorTierPrices,
  getVendorClinics
}
