export default class ValidationHelper {
  /**
   * @function isNumberInput
   * @param {evt}
   * @returns {Boolean}
   */
  isNumberInput(evt, isNegativeAllowed = false) {
    evt = evt || window.event;
    const charCode = evt.which ? evt.which : evt.keyCoode;
    // 46 is dot
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      // 45 is minus/negative sign
      if (isNegativeAllowed && charCode === 45) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  validateSearchParam(value) {
    let validValue = value;
    const trimmedQuery = validValue.trim();
    if (trimmedQuery && /[^a-zA-Z0-9+ ]/.test(trimmedQuery)) {
      validValue = encodeURIComponent(trimmedQuery);
    } else {
      validValue = trimmedQuery;
    }
    return validValue;
  }
}
