import { get } from "lodash";
import axios from "@/lib/axios";
import notification from "@/utils/notifications";

export default {
  state: {
    paymentsCard: [],
    user: {},
    users: [],
    details: {},
    payoutAccount: {},
    registrations: [],
    pricingTiers: [],
    pricingTiers1: [],
    availableOrgs: [],
    products: [],
    currentOrderDetails: {
      buyer: {},
      estShippingRateId: "",
      items: [],
      subtotal: 0,
      total: 0,
      shipToAddress: {
        shippingAddress: "",
        shippingRegion: "",
        shippingCity: "",
        shippingPostCode: "",
      },
    },
    locationNameOnOrder: "",
    shippingMethodOnOrder: "",
    sellerOrgs: [],
    tierObj: {},
    accountFees: {
      id: "",
      shippingUpcharge: 10,
      providerNetworkFee: 10,
      patientPortalFee: 0,
      apiFee: 0,
      isMarketPlaceFee: false,
      isLaboratoryFee: false,
      issShippingUpcharge: false,
      isEasypostFee: false,
      isProviderNetworkFee: false,
      isPatientPortalFee: false,
      isApiFee: false,
    },
    activeInventoryslots: "",
    activeInventoryAllslots: [],
    scanInventory: "",
    fulfilledBarcodes: {},
    barcodeDetails: {},
    barcodeBase64: [],
    selectedVendor: null,
  },

  getters: {
    getSelectedVendor: state => {
      return state.selectedVendor ? state.selectedVendor : localStorage.getItem("selected_vendor");
    },
    getBarcodeDetails: state => {
      return state.barcodeDetails;
    },
    getVendorCreditCards: state => {
      return state.paymentsCard[0];
    },
    getAllFulfilledBarcodes: state => {
      return state.fulfilledBarcodes;
    },
    getTierPricing: state => {
      return state.tierObj;
    },
    getVendorId: state => {
      return state.user.id;
    },
    getVendorName: state => {
      return state.details.name;
    },
    getVendorUser: state => {
      return state.user;
    },
    getVendorShippingCarrier: state => {
      return state.details.shippingCarrier;
    },
    getVendorUsers: state => {
      return state.users;
    },
    getVendorDetails: state => {
      return state.details;
    },
    getVendorReturnPolicy: state => {
      return state.details.returnPolicy;
    },
    getVendorShipToStates: state => {
      return state.details.shipsTo;
    },
    getVendorAchAccount: state => {
      return state.payoutAccount;
    },
    getVendorRegistrations: state => {
      return state.registrations;
    },
    getVendorPricingTiers: state => {
      return state.pricingTiers.sort((a, b) => {
        if (a.base < b.base) {
          return 1;
        }
        if (a.base === b.base) {
          if (a.name > b.name) {
            return 1;
          }
          return -1;
        }
        return -1;
      });
    },
    getAvailableOrgs: state => {
      return state.availableOrgs;
    },
    getVendorProducts: state => {
      return state.products;
    },
    getVendorOrderDetails: state => {
      return state.currentOrderDetails;
    },
    getCurrentOrderStatus: state => {
      return state.currentOrderDetails.status;
    },
    getCurrentOrderStatusHuman: state => {
      if (state.currentOrderDetails.status === "Processing") {
        return "Pending";
      }
      return state.currentOrderDetails.status;
    },
    getLocationNameOnOrder: state => {
      return state.locationNameOnOrder;
    },
    getShippingMethodOnOrder: state => {
      return state.shippingMethodOnOrder;
    },
    getVendorAccountFees: state => {
      return state.accountFees;
    },
    getActiveInventoryslots: state => {
      return state.activeInventoryslots;
    },
    getActiveInventoryAllslots: state => {
      return state.activeInventoryAllslots;
    },
    getScanInventory: state => {
      return state.scanInventory;
    },
    getBarcodeBase64: state => {
      return state.barcodeBase64;
    },
  },

  mutations: {
    setSelectedVendor(state, payload) {
      if (!payload) {
        localStorage.removeItem("selected_vendor");
      }
      state.selectedVendor = payload;
    },
    setAllFulfilledBarcodes(state, payload) {
      state.fulfilledBarcodes = payload;
    },
    setTierPricing(state, payload) {
      state.tierObj = payload;
    },
    setVendorUserDetails(state, payload) {
      state.user = payload;
    },
    setVendorDetails(state, payload) {
      state.details = payload;
    },
    setVendorReturnPolicy(state, payload) {
      state.details.returnPolicy = payload.returnPolicy;
    },
    setVendorShipToStates(state, payload) {
      state.details.shipsTo = payload.shipsTo;
    },
    setVendorProducts(state, payload) {
      state.products = payload;
    },
    setVendorOrderDetails(state, payload) {
      state.currentOrderDetails = payload;
    },
    setLocationOnOrder(state, payload) {
      state.locationNameOnOrder = payload.name;
    },
    setShippingMethodOnOrder(state, payload) {
      state.shippingMethodOnOrder = `${payload.carrier} ${payload.service}`;
    },
    setVendorAchAccount(state, payload) {
      state.payoutAccount = payload[0];
    },
    setVendorRegistrations(state, payload) {
      state.registrations = payload;
    },
    setVendorPricingTiers(state, payload) {
      state.pricingTiers = payload;
    },
    setNewVendorPricingTiers(state, payload) {
      state.pricingTiers1 = payload;
    },
    setAvailableOrgs(state, payload) {
      state.availableOrgs = payload;
    },
    setVendorCreditCards(state, payload) {
      state.paymentsCard = payload;
    },
    setVendorAccountFees(state, payload) {
      state.accountFees = payload;
    },
    setActiveInventoryslots(state, payload) {
      state.activeInventoryslots = payload;
    },
    setActiveInventoryAllslots(state, payload) {
      state.activeInventoryAllslots = payload;
    },
    addInventoryCode(state, payload) {
      state.activeInventoryAllslots.push(payload);
    },
    setScanInventory(state, payload) {
      state.scanInventory = payload;
    },
    setBarcodeDetails(state, payload) {
      state.barcodeDetails = payload;
    },
    setBarcodeBase64(state, payload) {
      state.barcodeBase64 = payload;
    },
    updateInventoryLot(state, payload) {
      const lotIndex = state.activeInventoryAllslots.findIndex(lot => {
        return lot[0].slotNumber === payload.slotNumber;
      });
      if (lotIndex >= 0) {
        state.activeInventoryAllslots[lotIndex][0].lotName = payload.value;
      }
    },
    updateVendorProduct(state, payload) {
      const selectedProduct = state.products.findIndex(product => product.id === payload.id);
      state.products.splice(selectedProduct, 1, payload);
    },
  },

  actions: {
    fetchFulfilledBarcodeDetails: async ({ commit }, payload) => {
      const { barCode } = payload;
      const response = await axios.get(`/orders/v1/vendor/barcode/${barCode}`);
      commit("setBarcodeDetails", response.data);
    },
    addVendorCardAccount: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const body = {
        cardholderName: payload.cardholderName,
        email: payload.email,
        cardNumber: payload.cardNumber,
        expDate: payload.expDate,
        cardType: payload.cardType,
        billingAddress: payload.billingAddress,
        cvv2: payload.cvv2,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
      };
      const response = await axios.post(`/payments/v1/card/vendor/${vendorId}`, body);
      commit("setVendorCreditCards", response.data);
    },
    fetchAllCreditCards: async ({ commit }, vendorId) => {
      const response = await axios.get(`/payments/v1/card/vendor/${vendorId}/method`);
      commit("setVendorCreditCards", response.data);
    },
    fetchTierById: async ({ commit }, payload) => {
      const { tierId } = payload;
      const { vendorId } = payload;
      const response = await axios.get(`/products/v1/vendor/${vendorId}/tier/${tierId}`);
      commit("setTierPricing", response.data);
    },
    fetchVendorTiers: async ({ commit }, vendorId) => {
      try {
        const response = await axios.get(`/products/v1/vendor/${vendorId}/tier`);
        commit("setVendorPricingTiers", response.data);
        return response.data;
      } catch (error) {
        return null;
      }
    },
    fetchPricingTier: async payload => {
      const { vendorId } = payload;
      const { tierId } = payload;
      await axios.get(`/products/v1/vendor/${vendorId}/tier/${tierId}`);
    },
    fetchSelectedShippingRate: async ({ commit }, rateId) => {
      const response = await axios.get(`/shipping/v1/rate/${rateId}`);
      commit("setShippingMethodOnOrder", response.data);
    },
    fetchVendorDetails: async ({ commit }, vendorId) => {
      const response = await axios.get(`/accounts/v1/vendor/${vendorId}`);
      commit("setVendorDetails", response.data);
    },
    cancelCurrentVendorOrder: async ({ commit, getters }) => {
      const vendorId = getters.getVendorId;
      const { fulfillmentId } = getters.getVendorOrderDetails;
      const response = await axios.post(`/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/cancel`, {});
      commit("setVendorOrderDetails", response.data);
    },
    fetchAllVendorProducts: async ({ commit }, vendorId) => {
      const response = await axios.get(`/products/v1/vendor/${vendorId}/product`);
      commit("setVendorProducts", response.data);
    },
    fetchVendorUser: async payload => {
      const { vendorId } = payload;
      const { userId } = payload;

      await axios.get(`/accounts/v1/vendor/${vendorId}/user/${userId}`);
    },
    fetchVendorRegistrations: async ({ commit }, vendorId) => {
      try {
        const selectedVendor = get(localStorage, "selected_vendor", vendorId);
        const response = await axios.get(`/accounts/v1/vendor/${selectedVendor}/registration`);
        if (response) {
          const { data } = response;
          commit("setVendorRegistrations", data);
        }
      } catch (error) {
        notification("warning", error.response.data.message);
      }
    },
    fetchFulfillment: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { fulfillmentId } = payload;

      const response = await axios.get(`/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}`);
      commit("setVendorOrderDetails", response.data);
    },
    fetchAccountFees: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const response = await axios.get(`/products/v1/vendor/${vendorId}/fees`);
      commit("setVendorAccountFees", response.data);
    },
    addAccountFees: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const body = {
        shippingUpcharge: payload.shippingUpchargeAmount,
        providerNetworkFee: payload.providerNFeeAmount,
        patientPortalFee: payload.patientPortalFeeAmount,
        apiFee: payload.apiFeeAmount,
        isMarketPlaceFee: payload.marketplaceFee,
        isLaboratoryFee: payload.laboratoryFee,
        isShippingUpcharge: payload.shippingUpcharge,
        isEasypostFee: false,
        isProviderNetworkFee: payload.providerNFee,
        isPatientPortalFee: payload.patientPortalFee,
        isApiFee: payload.apiFee,
      };

      const response = await axios.post(`/products/v1/vendor/${vendorId}/fees`, body);
      commit("setVendorAccountFees", response.data);
    },
    updateAccountFees: async ({ commit, getters }, payload) => {
      const { vendorId } = payload;
      const accountFeeId = getters.getVendorAccountFees.id;
      const body = {
        shippingUpcharge: payload.shippingUpchargeAmount,
        providerNetworkFee: payload.providerNFeeAmount,
        patientPortalFee: payload.patientPortalFeeAmount,
        apiFee: payload.apiFeeAmount,
        isMarketPlaceFee: payload.marketplaceFee,
        isLaboratoryFee: payload.laboratoryFee,
        isShippingUpcharge: payload.shippingUpcharge,
        isEasypostFee: payload.isEasypostFee,
        isProviderNetworkFee: payload.providerNFee,
        isPatientPortalFee: payload.patientPortalFee,
        isApiFee: payload.apiFee,
      };

      const response = await axios.put(`/products/v1/vendor/${vendorId}/fees/${accountFeeId}`, body);
      commit("setVendorAccountFees", response.data);
    },
    updateVendorReturnPolicy: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const body = {
        returnPolicy: payload.returnPolicy,
      };

      const response = await axios.put(`/accounts/v1/vendor/${vendorId}/returnpolicy`, body);

      commit("setVendorReturnPolicy", response.data);
    },
    updateVendorShipToStates: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const body = payload.states;
      // where states is an array following format [...{ state: 'AZ' }]

      const response = await axios.put(`/accounts/v1/vendor/${vendorId}/shipsto`, body);

      commit("setVendorShipToStates", response.data);
    },

    // Add
    createShippingLabels: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { fulfillmentId } = payload;
      const body = payload.packages; // where packages is an array of package dim objects

      const response = await axios.post(`/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/parcel`, body);

      commit("setVendorOrderDetails", response.data);
    },
    createCustomTrackers: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { fulfillmentId } = payload;
      const body = payload.trackers; // where trackers is an array of tracker objects

      const response = await axios.post(`/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/tracker`, body);

      commit("setVendorOrderDetails", response.data);
    },
    deleteProduct: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { productId } = payload;

      const response = await axios.delete(`/products/v1/vendor/${vendorId}/product/${productId}`);

      commit("setVendorProducts", response.data);
    },
    // Actions
    approveVendorRegistration: async ({ dispatch }, payload) => {
      const { vendorId } = payload;
      const { registrationId } = payload;

      await axios.put(`/accounts/v1/vendor/${vendorId}/registration/${registrationId}`);

      dispatch("fetchVendorRegistrations", vendorId);
    },
    denyVendorRegistration: async ({ dispatch }, payload) => {
      const { vendorId } = payload;
      const { registrationId } = payload;
      const body = {
        reason: payload.reason,
      };
      const config = {
        data: body,
      };
      await axios.delete(`/accounts/v1/vendor/${vendorId}/registration/${registrationId}`, config);

      dispatch("fetchVendorRegistrations", vendorId);
    },
    postActiveInventoryslots: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { code } = payload;

      const response = await axios.post(`/orders/v1/vendor/${vendorId}/codegenerate/${code}`);

      commit("setActiveInventoryslots", response.data);
    },
    putLotName: async ({ commit }, payload) => {
      const { slotNumber } = payload;
      const { lotName } = payload;

      try {
        const response = await axios.put(`/orders/v1/lot/${slotNumber}/lotname/${lotName}`);

        commit("updateInventoryLot", {
          slotNumber,
          value: response.data,
        });

        notification("success", "Succesfully updated lot name");
      } catch (error) {
        return null;
      }
    },

    fetchActiveInventoryBarcodes: async ({ commit }, payload) => {
      const { vendorId } = payload;

      const response = await axios.get(`/orders/v1/vendor/${vendorId}`);
      commit("setActiveInventoryAllslots", response.data);
    },
    fetchAllFulfilledBarcodes: async ({ commit }, payload) => {
      const { vendorId } = payload;

      const response = await axios.get(`/orders/v1/vendor/${vendorId}/fullfilmentinventory`);
      commit("setAllFulfilledBarcodes", response.data);
    },
    fetchWarehouseInventoryAllslots: async ({ commit }, payload) => {
      const { vendorId } = payload;

      const response = await axios.get(`/orders/v1/vendor/${vendorId}/allcodes`);
      const newResponse = [];
      const slotId = [];
      response.data.forEach(element => {
        const tempSlotId = element.slotNumber;
        if (slotId.indexOf(element.slotNumber) === -1) {
          slotId.push(element.slotNumber);
          newResponse.push(
            response.data.filter(item => {
              return item.slotNumber === tempSlotId;
            })
          );
        }
      });
      commit("setActiveInventoryAllslots", newResponse);
    },
    postScanInventory: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { body } = payload;
      const response = await axios.post(`/orders/v1/vendor/${vendorId}/scaninventory`, body);

      commit("setScanInventory", response.data);
    },
    fetchBarcodeBase64: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { lotNumber } = payload;

      const response = await axios.get(`/orders/v1/vendor/${vendorId}/genrateQRCode/${lotNumber}`);

      commit("setBarcodeBase64", response.data);
    },
    // updateProductStockStatus: async ({ commit }, { vendorId, productId, inStock }) => {
    //   const response = await axios.post(`/products/v1/vendor/${vendorId}/product/${productId}/stock`, {
    //     inStock,
    //   });

    //   if (response) {
    //     commit("updateVendorProduct", response.data);
    //     notification(
    //       "success",
    //       `Product succesfully updated to ${response.data.inStock ? "In Stock" : "Out of Stock"}`
    //     );
    //     return response.data;
    //   }
    // },
  },
};
