import { isEmpty } from "lodash";
import { notification } from "ant-design-vue";
import axios from "@/lib/axios";

export default {
  state: {
    details: {
      labFee: 0,
      ispackage: false,
      productBundle: [],
      marketplaceStoreFront: true,
      practiceStoreFront: false,
      prescriptionStoreFront: false,
      boundByState: false,
      description: "",
      detail: "",
      id: "",
      images: [],
      files: [],
      manufacturer: "",
      name: "",
      categorySlug: "",
      subcategorySlug: "",
      productPricing: {},
      selectedCategory: 0,
      registrationRequired: false,
      sku: "",
      vendorSku: "",
      mfgSku: "",
      vin: "",
      vendorId: "",
      slug: "",
      weight: "",
      weightTypeId: 1,
      cardVolume: "",
      inStock: false,
      limited: false,
      limitedQty: 500,
      minQtyForPurchase: 1,
      rateType: "",
      labtestId: "",
    },
    pricing: [],
    labs: [],
    // ADD-EDIT PRODUCT
    productDetailsForm: {
      labFee: 0,
      ispackage: false,
      productBundle: [],
      marketplaceStoreFront: true,
      practiceStoreFront: false,
      prescriptionStoreFront: false,
      boundByState: false,
      description: "",
      detail: "",
      id: "",
      images: [],
      files: [],
      manufacturer: "",
      name: "",
      categorySlug: "",
      subcategorySlug: "",
      productPricing: {},
      selectedCategory: 0,
      registrationRequired: false,
      sku: "",
      vendorSku: "",
      vin: "",
      vendorId: "",
      slug: "",
      weight: 1,
      weightTypeId: 1,
      cardVolume: "",
      inStock: false,
      limited: false,
      limitedQty: 500,
      minQtyForPurchase: 1,
      rateType: "C",
      labtestId: "",
      isVisibleMedcenter: false,
      productPricingTiers: [],
      isVirtualProduct: false,
    },
  },

  getters: {
    getProductDetailsForm: state => {
      return state.productDetailsForm;
    },
    getAllLaboratory: state => {
      return state.labs;
    },
    getProductsBundle: state => {
      return state.details.productBundle;
    },
    getCurrentVendorProduct: state => {
      return state.details;
    },
  },

  mutations: {
    setCurrentVendorProduct(state, payload) {
      state.details = payload;
    },
    setProductsBundle(state, payload) {
      state.productDetailsForm.productBundle = payload;
    },
    setAllLabs(state, payload) {
      state.labs = payload;
    },
    setProductFormDetails(state, payload) {
      state.productDetailsForm = payload;
    },
    // VENDOR PRODUCT PAGE PRICES
    setProductFormProperty(state, payload) {
      state.productDetailsForm[payload.fieldName] = payload.value;
    },
  },

  actions: {
    commitProducts: ({ commit }, payload) => {
      commit("setProductsBundle", payload);
    },
    fetchAllLabs: async ({ commit }) => {
      const response = await axios.get(
        "/accounts/v1/vendor/getlabs",
      );
      commit("setAllLabs", response.data);
    },

    addNewProduct: async ({ commit, dispatch, getters }, payload) => {
      const currentProduct = getters.getProductDetailsForm;
      let vendorId = "";
      if (getters.getVendorId !== undefined) {
        vendorId = getters.getVendorId;
      } else {
        vendorId = payload.vendorId;
      }

      const body = {
        productName: currentProduct.name,
        description: currentProduct.description,
        sku: currentProduct.sku,
        vendorSku: currentProduct.vendorSku,
        mfgSku: currentProduct.mfgSku,
        registrationRequired: currentProduct.registrationRequired,
        boundByState: currentProduct.boundByState,
        categorySlug: currentProduct.categorySlug,
        subcategorySlug: currentProduct.subcategorySlug,
        weight: currentProduct.weight,
        weightTypeId: currentProduct.weightTypeId,
        cardVolume: currentProduct.cardVolume,
        limited: currentProduct.limited,
        limitedQty: currentProduct.limitedQty,
        minQtyForPurchase: currentProduct.minQtyForPurchase,
        marketplaceStoreFront: currentProduct.marketplaceStoreFront,
        practiceStoreFront: currentProduct.practiceStoreFront,
        prescriptionStoreFront: currentProduct.prescriptionStoreFront,
        childProducts: [],
        isBundle: false,
        rateType: currentProduct.rateType,
        labtestId: currentProduct.labtestId,
        labFee: currentProduct.labFee,
        isVisibleMedcenter: currentProduct.isVisibleMedcenter,
        isVirtualProduct: currentProduct.isVirtualProduct,
      };

      try {
        const response = await axios.post(
          `/products/v1/vendor/${vendorId}/product`,
          body,
        );

        notification.success({
          description: "Successfully added bundle",
        });

        commit("setProductFormDetails", response.data);
        commit("setCurrentVendorProduct", response.data);
        dispatch("fetchAllVendorProducts", vendorId);
        return response.data;
      } catch (error) {
        notification.warning({
          description: "Failed to add product",
        });
        return null;
      }
    },

    addImagesToProduct: async ({ commit, getters }, payload) => {
      const body = payload.images;
      const { productId } = payload;
      const { vendorId } = payload;

      const response = await axios.put(
        `/products/v1/vendor/${vendorId}/product/${productId}/image`,
        body,
      );
      commit("setCurrentVendorProduct", response.data);
      if (getters.getCurrentVendorProduct.productBundle.length > 0) {
        const value = [];
        getters.getCurrentVendorProduct.productBundle.forEach(product => {
          value.push(product.childProduct);
        });
        commit("setProductsBundle", value);
      }
    },

    removeImageFromProduct: async ({ commit }, payload) => {
      const { productId } = payload;
      const { vendorId } = payload;
      const { imageId } = payload;

      const response = await axios.delete(
        `/products/v1/vendor/${vendorId}/product/${productId}/image/${imageId}`,
      );
      commit("setCurrentVendorProduct", response.data);
      return response;
    },

    removeFileFromProduct: async ({ commit, getters }, payload) => {
      const productId = getters.getCurrentVendorProduct.id;
      const { vendorId } = getters.getCurrentVendorProduct;
      const { fileId } = payload;

      const response = await axios.delete(
        `/products/v1/vendor/${vendorId}/product/${productId}/file/${fileId}`,
      );
      commit("setCurrentVendorProduct", response.data);
      return response;
    },

    resetCurrentVendorProduct: ({ commit }) => {
      const details = {
        boundByState: false,
        description: "",
        detail: "",
        id: "",
        images: [],
        manufacturer: "",
        name: "",
        categorySlug: "",
        subcategorySlug: "",
        productPricing: {},
        registrationRequired: false,
        sku: "",
        vendorSku: "",
        mfgSku: "",
        slug: "",
        vendor: {},
        weight: 0,
        weightTypeId: 1,
        cardVolume: "",
        marketplaceStoreFront: true,
        practiceStoreFront: false,
        prescriptionStoreFront: false,
        rateType: "C",
        isVisibleMedcenter: false,
        minQtyForPurchase: 0,
        isVirtualProduct: false,
      };
      commit("setProductFormDetails", details);
    },
  },
};
